<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
          transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" label="Mes a consultar" prepend-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="date" type="month" color="primary" no-title scrollable
            :max="new Date().toISOString().substr(0, 7)">
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn text @click="$refs.menu.save(date)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="justify-space-around">
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card class="pa-5" color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 text--secondary font-weight-black">
                  {{ computedTotales | numberFormat }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Total </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card class="pa-5" color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 text--secondary font-weight-black">
                  {{ computedFabrica | numberFormat }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Producción </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card class="pa-5" color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 text--secondary font-weight-black">
                  {{ computedOficina | numberFormat }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Staff </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="4" class="pa-2 ">
        <v-card color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
          <span class="title font-weight-light">Acumulado altas </span>
          <span class="display-1 text--secondary font-weight-black">
            {{ total_altas | numberFormat }}
          </span>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="pa-2">
        <v-card color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
          <span class="title font-weight-light"> Acumulado bajas </span>
          <span class="display-1 text--secondary font-weight-black">
            {{ total_bajas | numberFormat }}
          </span>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="pa-2">
        <v-card color="grey" :class="hover ? 'lighten-2' : 'lighten-4'">
          <span class="title font-weight-light"> Total </span>
          <span class="display-1 text--secondary font-weight-black">
            {{ gran_total | numberFormat }}
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card id="altas-bajas">
          <v-toolbar flat>
            <v-toolbar-title> Altas/Bajas Diarias </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart ref="chartDiaria" theme="walden" class="ma-auto" style="width: 100%" :options="chartDiaria"
              autoresize />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TweenMax } from "gsap";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
  name: "Trabajadores",
  components: {
    "v-chart": ECharts,
  },
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    totales: 0,
    fabrica: 0,
    oficina: 0,
    total_altas: 0,
    total_bajas: 0,
    gran_total: 0,
    tweenTotales: 0,
    tweenFabrica: 0,
    tweenOficina: 0,
    chartDiaria: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          let total2 = params.reduce((tot, b) => tot + b.value, 0);
          let total = 0;

          for (let i = 0; i < params.length; i++) {
            let perc = ((params[i].value / total2) * 100).toFixed(2);
            perc = isNaN(perc) ? 0 : perc;

            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.numberFormat(params[i].value) +
              " ( " +
              perc +
              "% )"; // : every 2nth
            if (params[i].seriesName === "Altas") {
              total += params[i].value;
            } else {
              total -= params[i].value;
            }

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          output +=
            "<br/><br/>Total: " + vm.$options.filters.numberFormat(total);
          return output;
        },
      },
      legend: {
        data: ["Altas", "Bajas"],
      },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Altas",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Bajas",
          // stack: "total",
          type: "bar",
          barGap: "-100%",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  }),
  computed: {
    computedTotales() {
      return this.tweenTotales.toFixed(0);
    },
    computedFabrica() {
      return this.tweenFabrica.toFixed(0);
    },
    computedOficina() {
      return this.tweenOficina.toFixed(0);
    },
  },
  watch: {
    date: function () {
      this.getPersonalReport();
    },
    totales(newValue) {
      TweenMax.to(this.$data, 0.5, { tweenTotales: newValue });
    },
    fabrica(newValue) {
      TweenMax.to(this.$data, 0.5, { tweenFabrica: newValue });
    },
    oficina(newValue) {
      TweenMax.to(this.$data, 0.5, { tweenOficina: newValue });
    },
  },
  mounted() {
    this.getPersonalReport();
  },
  methods: {
    getPersonalReport() {
      // this.clientsLoading = true;
      this.$refs.chartDiaria.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      console.log(this.date)
      this.$http
        .post("human_resource/getPersonalReport", {
          date: this.date,
        })
        .then((res) => {
          console.log("mis ingresos")
          console.log(res.data.total_ingresos)
          // this.clientes = res.data.data;
          this.fabrica = res.data.cards.semana;
          this.oficina = res.data.cards.quincena;
          this.totales = parseInt(this.fabrica) + parseInt(this.oficina);
          this.total_altas = res.data.total_ingresos;
          this.total_bajas = res.data.total_bajas;
          this.gran_total = parseInt(this.total_altas) - parseInt(this.total_bajas);

          this.$nextTick(() => {
            this.$refs.chartDiaria.chart.setOption({
              xAxis: {
                type: "category",
                data: res.data.mes,
              },
              series: [
                {
                  name: "Altas",
                  data: res.data.ingresos,
                },
                {
                  name: "Bajas",
                  data: res.data.bajas,
                },
              ],
            });
          });
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        })
        .finally(() => {
          this.$refs.chartDiaria.chart.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
</style>
